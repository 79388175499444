import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled.footer`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 1rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`


export const Footer = () => {
  return (
    <FooterContainer>
      <a href="https://github.com/lenitio/lenni.io"  rel="noopener noreferrer" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 64 64">
          <path fill="#fff" fillRule="evenodd" d="M32,1 C14.32,1 0,15.2233888 0,32.7841091 C0,46.8485774 9.16,58.7278882 21.88,62.9392827 C23.48,63.2173936 24.08,62.2638704 24.08,61.4295375 C24.08,60.6746649 24.04,58.1716663 24.04,55.5097472 C16,56.9797622 13.92,53.5629705 13.28,51.7751143 C12.92,50.8613212 11.36,48.0404815 10,47.2856089 C8.88,46.6896569 7.28,45.2196418 9.96,45.1799117 C12.48,45.1401816 14.28,47.4842596 14.88,48.4377829 C17.76,53.2451294 22.36,51.8943047 24.2,51.0599719 C24.48,48.9940048 25.32,47.60345 26.24,46.8088473 C19.12,46.0142446 11.68,43.2728651 11.68,31.1154434 C11.68,27.6589215 12.92,24.7983517 14.96,22.5734641 C14.64,21.7788613 13.52,18.5209902 15.28,14.1506752 C15.28,14.1506752 17.96,13.3163423 24.08,17.4085463 C26.64,16.6934039 29.36,16.3358327 32.08,16.3358327 C34.8,16.3358327 37.52,16.6934039 40.08,17.4085463 C46.2,13.2766122 48.88,14.1506752 48.88,14.1506752 C50.64,18.5209902 49.52,21.7788613 49.2,22.5734641 C51.24,24.7983517 52.48,27.6191914 52.48,31.1154434 C52.48,43.3125953 45,46.0142446 37.88,46.8088473 C39.04,47.8021007 40.04,49.7091472 40.04,52.6889075 C40.04,56.9400321 40,60.3568238 40,61.4295375 C40,62.2638704 40.6,63.2571238 42.2,62.9392827 C54.84,58.7278882 64,46.8088473 64,32.7841091 C64,15.2233888 49.68,1 32,1 Z"/>
        </svg>
      </a>
    </FooterContainer>
  )
}